import React, { useState } from "react";
import axiosInstance from "../../../utils/http-axios";
import { SparklesIcon, ArrowPathIcon } from "@heroicons/react/24/outline";
import Button from "../../layout/Button";
import { downloadMessages } from "../helpers/chat-helper";

const ChatCanceled: React.FC<any> = ({ onOpenNewTicket }) => {
  const [isLoading, setLoading] = useState(false);

  const openNewTicketHandler = () => {
    onOpenNewTicket();
  };

  return (
    <div
      className="flex rounded-tl-2xl rounded-tr-2xl items-center flex-col justify-center border-t border-gray-200"
      style={{ height: "100%" }}
    >
      <div className="flex  flex-col justify-center items-center">
        <SparklesIcon className="h-6 w-6 text-gray-500 mb-2" />
        <p className="text-xs mb-3">Terima Kasih!</p>
        <div className="mt-1">
          <Button
            className="text-white"
            onClick={openNewTicketHandler}
            loading={isLoading}
          >
            Buka Tiket Baru
          </Button>
        </div>
      </div>
    </div>
  );
};

export default ChatCanceled;
