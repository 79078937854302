import ChatContainer from "../../modules/chat-container";

const Chats = () => {
  return (
    <div style={{ maxWidth: 500 }} className="mx-auto">
      {/* <TicketForm /> */}
      <ChatContainer />
    </div>
  );
};

export default Chats;
