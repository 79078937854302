import {
  ArrowPathIcon,
  ExclamationCircleIcon,
} from "@heroicons/react/24/solid";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import axiosInstance from "../../utils/http-axios";
import { getLocalStorage, setLocalStorage } from "../../utils/localstorage";
import { removeTicketInfoCookies } from "../chat-container/helpers/chat-helper";

const SplashScreen = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [error, setError] = useState("");

  const getToken = async (token: string) => {
    try {
      const response = await axiosInstance.get(
        `/api/user-token/?token=${token}`
      );
      if (response.data.code === "0000") {
        let token = response.data.data;
        const currentToken = getLocalStorage("_t");

        if ((currentToken ?? "") != token) {
          removeTicketInfoCookies();
        }
        setLocalStorage("_t", token);
        navigate("/chat", { replace: true });
      } else {
        setError("Your Account is Invalid");
      }
    } catch (error) {
      setError("Something went wrong");
    }
  };

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const key = queryParams.get("k");
    getToken(key ?? "");
  }, [location.search]);

  return (
    <div className="full-height flex flex-col items-center justify-center">
      {error ? (
        <div className="flex flex-col items-center">
          <ExclamationCircleIcon
            className="text-red-600 mb-3"
            style={{ width: 80 }}
          />{" "}
          <div className="flex items-center justify-center text-gray-500">
            <div className="font-semibold text-s">{error}</div>
          </div>
        </div>
      ) : (
        <div className="flex flex-col items-center">
          <img
            src="/logo.png"
            style={{ width: 100 }}
            alt="logo"
            className="mb-3"
          />
          <div className="flex items-center justify-center text-gray-500 font-semibold">
            <ArrowPathIcon className="h-5 w-5 me-2 inline-flex animate-spin" />
            <div className="text-s">Initiating, Please Wait ...</div>
          </div>
        </div>
      )}
    </div>
  );
};

export default SplashScreen;
