import { format, parseISO } from "date-fns";

const dateNow = () => {
  return new Date().toISOString();
};

const formatDateTime = (date: any) => {
  if (!date) return "";
  return format(parseISO(date), "dd/MM/yyyy HH:mm");
};

const formatTime = (date: any) => {
  if (!date) return "";
  return format(parseISO(date), "HH:mm");
};

function isWithinTimeRange(startTime: string, endTime: string) {
  const now = new Date();
  const currentTime = now.getHours() * 60 + now.getMinutes();

  const [startHour, startMinute] = startTime.split(":").map(Number);
  const [endHour, endMinute] = endTime.split(":").map(Number);

  const startTimeInMinutes = startHour * 60 + startMinute;
  const endTimeInMinutes = endHour * 60 + endMinute;

  return currentTime >= startTimeInMinutes && currentTime <= endTimeInMinutes;
}

const daysOfWeek: { [key: string]: string } = {
  Sunday: "Minggu",
  Monday: "Senin",
  Tuesday: "Selasa",
  Wednesday: "Rabu",
  Thursday: "Kamis",
  Friday: "Jumat",
  Saturday: "Sabtu",
};

const getIndonesianDay = (day: string): string => {
  return daysOfWeek[day] || "Invalid day";
};

export { formatDateTime, formatTime, dateNow, isWithinTimeRange, getIndonesianDay };
