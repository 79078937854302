import React, { useRef, useLayoutEffect } from "react";
import { Scrollbars } from "rc-scrollbars";

interface ScrollableDivProps {
  children: any;
  height: string;
  triggerScrollBottom?: any;
}

const ScrollableDiv: React.FC<ScrollableDivProps> = ({ children, height }) => {
  const scrollableDivRef = useRef<any>(null);

  const scrollToBottom = () => {
    const scrollableDiv = scrollableDivRef.current;
    scrollableDiv.scrollToBottom();
  };

  useLayoutEffect(() => {
    scrollToBottom();
  }, [children]);

  return (
    <Scrollbars style={{ height, padding: 12 }} ref={scrollableDivRef}>
      {children}
    </Scrollbars>
  );
};

export default ScrollableDiv;
