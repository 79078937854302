import React, { createContext, useContext, useState, ReactNode } from "react";
import { isWithinTimeRange } from "../utils/date";

export interface TicketState {
  code?: string | null;
  tokenCode?: string | null;
  currentAssignedAgentName?: string | null;
  allowChat?: boolean;
  isReset?: boolean;
  userName?: string;
  agentAvatar?: string;
  status?: number;
  queueNumber?: number;
  isLoadingChat?: boolean;
  isNeedUserResponse?: boolean;
  showContactAgent?: boolean;
  isAllowRating?: boolean;
}

export interface OperationalState {
  enableOperationalTime?: boolean | null;
  startOperationalTime?: string | null;
  endOperationalTime?: string | null;
  isOffDay?: boolean | null;
  nextAvailable?: string | null;
}

interface AppContextData {
  ticket: TicketState;
  setTicket: (state: TicketState) => void;
  loading: boolean;
  setLoading: (state: boolean) => void;
  operationalSetting: OperationalState;
  setOperationalSetting: (state: OperationalState) => void;
  isOperationalTime: boolean | null;
  setIsOperationalTime: (state: boolean) => void;
  isLoadingChat: boolean | null;
  setIsLoadingChat: (state: boolean) => void;
}

interface AppContextProps {
  children: ReactNode;
}

// Create the context
const AppContext = createContext<AppContextData | undefined>(undefined);

// Create a provider component that will wrap your app
const AppProvider: React.FC<AppContextProps> = ({ children }) => {
  const [ticket, setTicket] = useState<TicketState>({
    code: null,
    allowChat: false,
  });
  const [loading, setLoading] = useState<boolean>(false);
  const [isOperationalTime, setIsOperationalTime] = useState<boolean | null>(
    null
  );
  const [isLoadingChat, setIsLoadingChat] = useState(false);

  const [operationalSetting, setOperationalSetting] =
    useState<OperationalState>({
      enableOperationalTime: null,
      startOperationalTime: null,
      endOperationalTime: null,
      isOffDay: null,
      nextAvailable: null,
    });

  const contextValue: AppContextData = {
    ticket,
    setTicket,
    loading,
    setLoading,
    operationalSetting,
    setOperationalSetting,
    isOperationalTime,
    setIsOperationalTime,
    isLoadingChat,
    setIsLoadingChat,
  };

  return (
    <AppContext.Provider value={contextValue}>{children}</AppContext.Provider>
  );
};

const useAppContext = () => {
  const context = useContext(AppContext);

  if (!context) {
    throw new Error("useAppContext must be used within an AppProvider");
  }

  return context;
};

export { AppProvider, useAppContext };
