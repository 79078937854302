import SplashScreen from "../modules/splashscreen/SplashScreen";
import Chats from "../pages/chats";

const MainRoutes = {
  path: "/",
  //   element: <MainLayout />,
  children: [
    {
      path: "/",
      element: <SplashScreen />,
    },
    {
      path: "/chat",
      element: <Chats />,
    },
  ],
};

export default MainRoutes;
