// src/components/Modal.tsx
import React, { useEffect, useState } from "react";
// Import styles
import "@react-pdf-viewer/core/lib/styles/index.css";
import "@react-pdf-viewer/default-layout/lib/styles/index.css";
import axiosInstance from "../../../utils/http-axios";
import { moveFirstToLast } from "../../../utils/array";
import { getIndonesianDay } from "../../../utils/date";

interface ModalProps {
  isOpen: boolean;
  onClose: () => void;
  title: string;
}

const OperationalSchedule: React.FC<ModalProps> = ({
  isOpen,
  onClose,
  title,
}) => {
  const [schedules, setSchedules] = useState<any>([]);

  const getSchedule = async () => {
    const { data, status } = await axiosInstance.get(
      "api/operational-schedules"
    );
    if (status === 200) {
      if (data.code === "0000") {
        const items = JSON.parse(data.data.value);
        setSchedules(moveFirstToLast(items));
      }
    }
  };

  useEffect(() => {
    if (isOpen) {
      getSchedule();
    }
  }, [isOpen]);

  useEffect(() => {
    const handleEscape = (event: KeyboardEvent) => {
      if (event.key === "Escape") {
        onClose();
      }
    };

    document.addEventListener("keydown", handleEscape);

    return () => {
      document.removeEventListener("keydown", handleEscape);
    };
  }, [onClose]);

  const handleClickOutside = (event: React.MouseEvent) => {
    if (event.target === event.currentTarget) {
      onClose();
    }
  };

  return isOpen ? (
    <div
      className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center px-5 py-5"
      style={{ zIndex: 999 }}
      onClick={handleClickOutside}
    >
      <div
        className="bg-white w-1/3 rounded-lg overflow-hidden shadow-lg"
        style={{ width: "100%", maxWidth: 400 }}
      >
        <div className="bg-gray-100 px-6 py-4 flex justify-between items-center">
          <h2 className="text-sm font-semibold text-gray-800">{title}</h2>
          <button
            className="text-gray-500 hover:text-gray-800"
            onClick={onClose}
          >
            &times;
          </button>
        </div>
        <div className="px-10 py-6 flex align-center justify-center items-center w-full">
          {schedules && (
            <div className="w-full">
              {schedules.map((m: any, index: number) => (
                <div
                  key={index}
                  className="flex justify-between py-3 text-sm border-b border-gray-200"
                >
                  <div>{getIndonesianDay(m.label)}</div>
                  <div className="text-center" style={{ width: 90 }}>
                    {m.enabled ? (
                      <>
                        {m.start}-{m.end}
                      </>
                    ) : (
                      <>-</>
                    )}
                  </div>
                </div>
              ))}
            </div>
          )}
        </div>
      </div>
    </div>
  ) : (
    <></>
  );
};

export default OperationalSchedule;
