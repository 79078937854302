import { useEffect, useState } from "react";
import axiosInstance from "../../../utils/http-axios";
import { useAppContext } from "../../../context/AppContext";
import { isWithinTimeRange } from "../../../utils/date";
import Button from "../../layout/Button";
import OperationalSchedule from "./OperationalSchedule";

const ChatOperationalTime = () => {
  const { operationalSetting, isOperationalTime } = useAppContext();
  const [showSchedule, setShowSchedule] = useState(false);

  return (
    <>
      <OperationalSchedule
        isOpen={showSchedule}
        title="Jadwal Operasional"
        onClose={() => setShowSchedule(false)}
      />
      {isOperationalTime !== null && isOperationalTime === false && (
        <div
          className="unavailable-box"
          style={{
            height: 90,
            position: "absolute",
            bottom: 0,
            width: "100%",
          }}
        >
          <div
            className="flex rounded-tl-2xl rounded-tr-2xl items-center flex-col justify-center border-t border-gray-200"
            style={{ height: "100%" }}
          >
            <p className="text-sm">Layanan tidak tersedia</p>
            <div className="text-xs text-gray-500 mb-1 text-center mx-8">
              {operationalSetting.isOffDay
                ? "Mohon maaf, layanan kami sedang tidak tersedia hari ini."
                : "Mohon maaf, layanan kami sedang tidak tersedia karena di luar jam operasional."}
              <div>
                <button
                  type="button"
                  onClick={() => setShowSchedule(true)}
                  className={`bg-blue-500 disabled:opacity-50 text-xs py-1 px-3 rounded text-white ms-2 mt-2 cursor-pointer`}
                >
                  Lihat Jadwal
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default ChatOperationalTime;
