// src/components/Modal.tsx
import React, { useEffect, useState } from "react";
// Core viewer
import { Viewer, PdfJs, Worker } from "@react-pdf-viewer/core";

// Plugins
import { defaultLayoutPlugin } from "@react-pdf-viewer/default-layout";

// Import styles
import "@react-pdf-viewer/core/lib/styles/index.css";
import "@react-pdf-viewer/default-layout/lib/styles/index.css";
import ZoomableDiv from "../../layout/ZoomableDiv";

interface ModalProps {
  isOpen: boolean;
  onClose: () => void;
  title: string;
  mediaUrl?: string | null;
  fileName?: string | null;
}

const imageStyles = {
  width: "100%",
  maxHeight: "calc(100vh - 150px)",
};
const nonImageStyles = {
  width: "100%",
  height: "calc(100vh - 150px)",
};

const getFileType = (url: string): "image" | "pdf" | "video" | "unknown" => {
  const extension = url.split(".").pop()?.toLowerCase();

  const imageExtensions = ["jpg", "jpeg", "png", "gif", "bmp", "tiff", "svg"];
  const pdfExtensions = ["pdf"];
  const videoExtensions = ["mp4", "avi"];

  if (extension && imageExtensions.includes(extension)) {
    return "image";
  } else if (extension && pdfExtensions.includes(extension)) {
    return "pdf";
  } else if (extension && videoExtensions.includes(extension)) {
    return "video";
  } else {
    return "unknown";
  }
};

const MediaViewer: React.FC<ModalProps> = ({
  isOpen,
  onClose,
  title,
  mediaUrl,
  fileName,
}) => {
  const [fileType, setFileType] = useState<string>("");
  // Create new plugin instance
  const defaultLayoutPluginInstance = defaultLayoutPlugin({
    sidebarTabs: (defaultTabs) => [],
  });

  useEffect(() => {
    const handleEscape = (event: KeyboardEvent) => {
      if (event.key === "Escape") {
        onClose();
      }
    };

    document.addEventListener("keydown", handleEscape);

    return () => {
      document.removeEventListener("keydown", handleEscape);
    };
  }, [onClose]);

  const handleClickOutside = (event: React.MouseEvent) => {
    if (event.target === event.currentTarget) {
      onClose();
    }
  };

  useEffect(() => {
    if (mediaUrl) {
      setFileType(getFileType(fileName ?? ""));
    }
  }, [mediaUrl, fileName]);

  return isOpen ? (
    <div
      className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center px-5 py-5"
      style={{ zIndex: 999 }}
      onClick={handleClickOutside}
    >
      <div
        className="bg-white w-1/3 rounded-lg overflow-hidden shadow-lg"
        style={{ width: "100%", maxWidth: 800 }}
      >
        {/* Modal header */}
        <div className="bg-gray-100 px-4 py-2 flex justify-between items-center">
          <h2 className="text-lg font-semibold">{title}</h2>
          <button
            className="text-gray-500 hover:text-gray-800"
            onClick={onClose}
          >
            &times;
          </button>
        </div>
        {/* Modal body */}
        {mediaUrl && (
          <div
            className="p-4 flex align-center justify-center items-center"
            style={
              fileType === "image" ? { ...imageStyles } : { ...nonImageStyles }
            }
          >
            {fileType === "image" && (
              // <ZoomableDiv>
              <img
                src={mediaUrl}
                alt="Media"
                style={{
                  maxWidth: "100%",
                  maxHeight: "100%",
                }}
              />
              // </ZoomableDiv>
            )}
            {fileType === "pdf" && (
              // <iframe
              //   style={{ width: "100%", height: "100%" }}
              //   src={mediaUrl}
              // ></iframe>
              <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.4.120/build/pdf.worker.min.js">
                <Viewer
                  fileUrl={mediaUrl}
                  plugins={[defaultLayoutPluginInstance]}
                />
              </Worker>
            )}
            {fileType === "video" && (
              <div
                style={{
                  transformOrigin: "top left",
                }}
              >
                <video controls width="600">
                  <source src={mediaUrl} type="video/mp4" />
                  Your browser does not support the video tag.
                </video>
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  ) : (
    <></>
  );
};

export default MediaViewer;
