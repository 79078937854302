import CryptoJS from "crypto-js";
import { deleteCookie, getCookie } from "./cookies";

const encryptionKey = process.env.REACT_APP_ENCRYPT_KEY ?? "";

const getLocalStorage = (code: string): string | null => {
  const cookie = getCookie(code);
  if (cookie) {
    return cookie;
  }

  const data = localStorage.getItem(code);
  return data;
};

const setLocalStorage = (code: string, value: string): void => {
  // const data = CryptoJS.AES.encrypt(value, encryptionKey).toString();
  localStorage.setItem(code, value);
};

const deleteLocalStorage = (code: string): void => {
  deleteCookie(code);
  localStorage.removeItem(code);
};

export { getLocalStorage, setLocalStorage, deleteLocalStorage };
