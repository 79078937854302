import Cookie from "js-cookie";
import CryptoJS from "crypto-js";

const encryptionKey = process.env.REACT_APP_ENCRYPT_KEY ?? "";

const getCookie = (code: string): string | null => {
  const data = Cookie.get(code);
  if (!data) return null;

  // return CryptoJS.AES.decrypt(data, encryptionKey).toString();
  return data;
};

const setCookie = (code: string, value: string): void => {
  // const data = CryptoJS.AES.encrypt(value, encryptionKey).toString();
  Cookie.set(code, value);
};

const deleteCookie = (code: string): void => {
  Cookie.remove(code);
};

export { getCookie, setCookie, deleteCookie };
