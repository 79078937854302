import axios from "axios";
import { getLocalStorage } from "./localstorage";
import { showError } from "./toast";
import { API_SERVICE_UNAVAILABLE_CODE, API_SUCCESS_CODE } from "./constant";

const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  // timeout: 5000,
  headers: {
    "Content-Type": "application/json",
  },
});

axiosInstance.interceptors.request.use(
  (config) => {
    const token = getLocalStorage("_tc");

    if (token) {
      config.headers["Xtokencode"] = `${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

axiosInstance.interceptors.response.use(
  (response) => {
    if (response.status === 200) {
      if (
        response.data &&
        response.data.code !== API_SUCCESS_CODE &&
        response.data.code !== API_SERVICE_UNAVAILABLE_CODE
      ) {
        showError(response.data.message);
      }
    }
    return response;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export default axiosInstance;
