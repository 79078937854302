import React, { useEffect, useState } from "react";
import axiosInstance from "../../../utils/http-axios";
import { SparklesIcon, ArrowPathIcon } from "@heroicons/react/24/outline";
import Button from "../../layout/Button";
import {
  downloadMessages,
  removeTicketInfoCookies,
} from "../helpers/chat-helper";
import BlockLoading from "./BlockLoading";

const ChatRating: React.FC<any> = ({ ticket, onOpenNewTicket }) => {
  const [rating, setRating] = useState<number | null>(null);
  const [isRated, setIsRated] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [isLoadingComments, setIsLoadingComments] = useState(false);
  const [commentCategories, setCommentCategories] = useState<any[]>([]);
  const [showedComments, setShowedComments] = useState<any[]>([]);
  const [checkedItems, setCheckedItems] = useState<number[]>([]);
  const [commentText, setCommentText] = useState("");

  const handleShowedComments = (selectedRating: number) => {
    setShowedComments(
      commentCategories.find((f) => f.rating === selectedRating).items
    );
  };

  const handleStarClick = (selectedRating: number) => {
    setRating(selectedRating);
    handleShowedComments(selectedRating);
  };

  const submitHandler = async () => {
    setLoading(true);
    const response = await axiosInstance.put(`/api/rate/${ticket?.tokenCode}`, {
      rating,
      commentCategoryIds: checkedItems,
      commentText: commentText,
    });
    const { data, message, code } = response.data;
    if (code === "0000") {
      setLoading(false);
      setIsRated(true);
      removeTicketInfoCookies();
    }
  };

  const openNewTicketHandler = () => {
    onOpenNewTicket();
  };

  const getCommentCategories = async () => {
    setIsLoadingComments(true);
    try {
      const response = await axiosInstance.get(`/api/comment-categories`);

      const { data, message, code } = response.data;
      if (code === "0000") {
        setCommentCategories(data);
      }
    } catch (e) {
    } finally {
      setIsLoadingComments(false);
    }
  };

  const handleCheckboxChange = (id: number, isChecked: boolean) => {
    setCheckedItems((prev) =>
      isChecked ? [...prev, id] : prev.filter((item) => item !== id)
    );
  };

  useEffect(() => {
    getCommentCategories();
  }, []);

  return (
    <div
      className="px-1 py-4 flex rounded-tl-2xl rounded-tr-2xl items-center flex-col justify-center border-t border-gray-200"
      style={{ height: "100%" }}
    >
      {isLoadingComments && <BlockLoading />}
      {!ticket?.isAllowRating || isRated ? (
        <div className="flex  flex-col justify-center items-center w-full">
          <SparklesIcon className="h-6 w-6 text-gray-500 mb-2" />
          <p className="text-xs mb-3">Terima kasih atas feedback Anda!</p>
          <div className="mt-1">
            <Button
              className="text-white"
              onClick={openNewTicketHandler}
              loading={isLoading}
            >
              Buka Tiket Baru
            </Button>
          </div>
        </div>
      ) : (
        <div className="flex  flex-col justify-center items-center w-full">
          <p className="text-xs">Berikan penilaian untuk pelayanan kami</p>
          <div className="mb-3">
            {[1, 2, 3, 4, 5].map((star) => (
              <span
                key={star}
                className={`text-4xl cursor-pointer ${
                  rating && star <= rating ? "text-yellow-500" : "text-gray-300"
                }`}
                onClick={() => handleStarClick(star)}
              >
                &#9733;
              </span>
            ))}
          </div>
          {showedComments.length > 0 && (
            <>
              <div>
                <ul className="flex flex-wrap justify-center">
                  {showedComments.map((m, i) => (
                    <li className="flex-shrink-0 m-1" key={i}>
                      <input
                        type="checkbox"
                        id={`react-option-${m.id}`}
                        className="hidden peer"
                        value={m.id}
                        checked={checkedItems.includes(m.id)}
                        onChange={(e) =>
                          handleCheckboxChange(m.id, e.target.checked)
                        }
                        required
                      />
                      <label
                        htmlFor={`react-option-${m.id}`}
                        className="py-2 px-3 inline-flex items-center justify-between w-full text-gray-500 bg-gray-50 border border-gray-200 rounded-lg cursor-pointer  peer-checked:border-blue-600 hover:text-gray-600 peer-checked:text-blue-600 hover:bg-gray-50 peer-checked:bg-blue-100"
                      >
                        <div className="block">
                          <div className="w-full text-xs">{m.name}</div>
                        </div>
                      </label>
                    </li>
                  ))}
                </ul>
              </div>
              <div className="mb-4">
                {checkedItems.length === 0 && (
                  <span className="text-xs text-red-500">
                    * Silahkan pilih minimal 1
                  </span>
                )}
              </div>
              <div className="w-full px-8">
                <div className="text-xs text-center mb-2">
                  Berikan komentar Anda (Opsional)
                </div>
                <div className="flex justify-center">
                  <textarea
                    value={commentText}
                    onChange={(e) => setCommentText(e.target.value)}
                    className="w-60 border resize-none text-xs rounded-md px-3 py-2"
                    rows={2}
                    maxLength={100}
                  ></textarea>
                </div>
              </div>
            </>
          )}
          <div className="mt-3">
            <Button
              className="text-white"
              onClick={submitHandler}
              disabled={!rating || checkedItems.length === 0}
              loading={isLoading}
            >
              Kirim Penilaian
            </Button>
            {/* <Button className="ms-2 bg-gray-200 text-gray-500 disabled:text-gray-200" onClick={downloadTextHandler} loading={isLoading}>
              Download Percakapan
            </Button> */}
          </div>
        </div>
      )}
    </div>
  );
};

export default ChatRating;
