import { FC, useEffect, useState } from "react";
import axiosInstance from "../../../utils/http-axios";
import Button from "../../layout/Button";

type Props = {
  ticketCode: string;
  initNumber: number;
};

const ChatQueueInfo: FC<Props> = ({ ticketCode, initNumber }) => {
  const intervalMs = 30 * 1000;
  const [isLoading, setIsLoading] = useState(false);
  const [queueNumber, setQueueNumber] = useState(initNumber ?? null);

  const getQueue = async () => {
    setIsLoading(true);
    const response = await axiosInstance.get(`/api/queue/${ticketCode}`);
    const { data, message, code } = response.data;
    if (code === "0000") {
      setIsLoading(false);
      setQueueNumber(data);
    }
  };

  useEffect(() => {
    const interval = setInterval(() => {
      getQueue();
    }, intervalMs);

    setTimeout(() => {
      getQueue();
    }, 500);

    return () => clearInterval(interval);
  }, []);

  return (
    <div className="z-10 bg-white p-4 text-center">
      <div className="d-flex items-center px-2.5 py-0.5 text-xs text-center text-gray-500">
        <div>Silakan tunggu, Anda sedang dalam antrian.</div>
        <div className="mt-1">
          Antrian Ke:
          <span className="inline-flex items-center rounded-md bg-gray-50 px-2 py-1 text-xs font-medium text-gray-700 ring-1 ring-inset ring-blue-700/10 ms-2">
            {queueNumber === null ? "-" : queueNumber}
          </span>
        </div>
      </div>
    </div>
  );
};

export default ChatQueueInfo;
