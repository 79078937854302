import { formatTime } from "../../../utils/date";
import { ChevronDoubleRightIcon } from "@heroicons/react/24/solid";

const ChatQuestionItem = ({ onClick, name, code }: any) => {
  const clickHandler = () => {
    onClick({ code, name });
  };

  return (
    <li
      className="py-3 px-4 hover:bg-gray-200 cursor-pointer"
      onClick={clickHandler}
    >
      <div className="overflow-hidden">
        <p className="d-flex flex-center text-xs relative ps-5">
          <span className="absolute left-0">
            <ChevronDoubleRightIcon className="h-3 w-3 me-1 inline-flex" />
          </span>
          {name}
        </p>
      </div>
    </li>
  );
};

const ChatQuestionList = ({
  title,
  showTime = true,
  onClick,
  items,
  date,
}: any) => {
  return (
    <div className="chat-question-list bg-gray-100 rounded-br-3xl rounded-tr-3xl rounded-tl-xl text-black overflow-hidden">
      <div className="mr-2 py-3 px-4 bg-gray-100 text-xs font-bold ">
        <div>{title}</div>
      </div>
      <ul className="divide-y divide-slate-200 text-black">
        {items.map((m: any, index: number) => (
          <ChatQuestionItem
            key={index}
            code={m.code}
            name={m.name}
            onClick={onClick}
          />
        ))}
      </ul>
      {showTime && (
        <div className="flex justify-end mt-2 text-xxs px-4 pb-3">
          <div className="text-gray-500 font-medium">{formatTime(date)}</div>
        </div>
      )}
    </div>
  );
};

export default ChatQuestionList;
