import { SparklesIcon, ArrowPathIcon } from "@heroicons/react/24/outline";

const Button = ({
  children,
  disabled,
  onClick,
  className,
  loading = false,
  type = "button",
}: any) => {
  return (
    <button
      onClick={onClick}
      type={type}
      className={`bg-blue-500 disabled:opacity-50 text-xs font-semibold py-2 px-4 rounded ${className}`}
      disabled={disabled || loading}
    >
      {loading && (
        <ArrowPathIcon className="h-4 w-4 me-1 inline-flex animate-spin" />
      )}{" "}
      {children}
    </button>
  );
};

export default Button;
