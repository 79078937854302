import * as signalR from "@microsoft/signalr";
import { showError } from "./toast";

const URL = process.env.REACT_APP_HUB_URL ?? "https://localhost:7285/hub"; // or whatever your backend port is

class Connector {
  private topics: string[] = [];
  private connection: signalR.HubConnection;
  private connectedCallbacks: (() => void)[] = [];

  // public events!: (
  //   topic: string,
  //   onMessageReceived: (username: string, message: string) => void
  // ) => void

  public onConnected!: (callback: () => void) => void;

  public static instance: Connector;

  constructor() {
    this.connection = new signalR.HubConnectionBuilder()
      .withUrl(URL)
      .withAutomaticReconnect()
      .build();
    this.connection
      .start()
      .then(() => {
        this.connectedCallbacks.forEach((callback) => callback());
      })
      .catch((err) => showError(err));
    this.setupEventHandlers();
  }

  private setupEventHandlers() {
    // const eventHandlers: {[topic: string]: boolean} = {}

    // this.events = (topic, onMessageReceived) => {
    //   if (!eventHandlers[topic]) {
    //     this.topics.push(topic)
    //     this.connection.on(topic, (username, message) => {
    //       onMessageReceived(username, message)
    //     })

    //     eventHandlers[topic] = true
    //   }
    // }

    this.onConnected = (callback: () => void) => {
      this.connectedCallbacks.push(callback);
    };
  }

  public addEventHandler = (type: string, handler: (...args: any[]) => any) => {
    this.connection.on(type, handler);
  };

  public newMessage = (topic: string, messages: string) => {
    this.connection
      .send("newMessage", topic, messages)
      .then((x) => console.log("sent"));
  };

  public unsubscribe = (topic: string) => {
    if (this.connection && this.connection.off) {
      this.connection.off(topic); // Remove the event handler for the specified topic
    }
  };

  public isConnected = () => {
    return this.connection.state === signalR.HubConnectionState.Connected;
  };

  public static getInstance(): Connector {
    if (!Connector.instance) Connector.instance = new Connector();
    return Connector.instance;
  }
}

export default Connector.getInstance;
