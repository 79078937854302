import TypingIndicator from "./TypingIndicator";
import ChatQuestionList from "./ChatQuestionList";
import ChatAttachment from "./ChatAttachment";
import ChatRating from "./ChatRating";
import { memo } from "react";
import { formatTime } from "../../../utils/date";
import "./ChatItem.css";
import AvatarImage from "./AvatarImage";

const ChatStartText = ({
  title,
  text,
  date,
  showTime = true,
  children,
}: any) => {
  return (
    <div
      style={{ maxWidth: "80%" }}
      className="py-2 px-3 bg-gray-100 rounded-br-xl rounded-tr-xl rounded-bl-xl text-grey-400 text-xs"
    >
      {title && (
        <div className="mb-4 bg-gray-100 text-xs font-bold ">
          <div className="wrap-text ">{title}</div>
        </div>
      )}
      {children}
      {text && <div className="wrap-text ">{text}</div>}
      {showTime && (
        <div className="flex justify-end text-xxs">
          <div className="text-gray-500">{formatTime(date)}</div>
        </div>
      )}
    </div>
  );
};

const ChatEndText = ({ title, text, showTime = true, date, children }: any) => {
  return (
    <div
      style={{ maxWidth: "80%" }}
      className="min-w-20 py-2 px-3 bg-gray-100 rounded-bl-xl rounded-tl-xl rounded-br-xl text-grey-400 text-xs "
    >
      {title && (
        <div className="mb-4 bg-gray-100 text-xs font-bold ">
          <div className="wrap-text ">{title}</div>
        </div>
      )}
      {children}
      {text && <div className="wrap-text ">{text}</div>}
      {showTime && (
        <div className="flex justify-end text-xxs">
          <div className="text-gray-500">{formatTime(date)}</div>
        </div>
      )}
    </div>
  );
};

const ChatText = ({
  alignment = "",
  title,
  text,
  showTime = true,
  date,
  children,
}: any) => {
  if (alignment === "in")
    return (
      <ChatStartText
        text={text}
        title={title}
        date={date}
        children={children}
      />
    );
  else
    return (
      <ChatEndText text={text} title={title} date={date} children={children} />
    );
};

const ChatDate = () => {
  return (
    <div>
      <span className="inline-flex items-center px-2.5 py-0.5 rounded-md bg-teal-500 text-white text-sm font-semibold">
        Now
      </span>
    </div>
  );
};

const ChatJoin = ({ text }: any) => {
  return (
    // <div className="w-100 text-center">
    //   <div className="inline-flex items-center px-2.5 py-0.5 text-xs text-center text-gray-500">{text}</div>
    // </div>
    <div className="relative">
      <div className="z-10 bg-white p-4 text-center">
        <p className="inline-flex items-center px-2.5 py-0.5 text-xs text-center text-gray-500">
          {text}
        </p>
      </div>
      {/* <div className="absolute top-1/2 left-0 right-0 transform -translate-y-1/2 z-0 border-t border-black"></div> */}
    </div>
  );
};

const ChatItem = ({
  avatar,
  alignment,
  type,
  text,
  date,
  onListClick,
  items,
  title,
  path,
  fullpath,
  showAvatar = true,
  mimeType,
  uuid,
  onDownloadClick,
  fileName,
  isError,
}: any) => {
  const chatContent = () => {
    switch (type) {
      case "list":
        return (
          <ChatQuestionList
            title={title}
            onClick={onListClick}
            items={items}
            date={date}
          />
        );
      case "loading":
        return (
          <ChatText
            alignment={alignment}
            text={<TypingIndicator />}
            showTime={false}
          />
        );
        case "date":
          return <ChatDate />;
      case "notif":
        return <ChatJoin text={text} />;
      case "attachment":
        return (
          <ChatText alignment={alignment} date={date}>
            <ChatAttachment
              fullpath={fullpath}
              path={path}
              mimeType={mimeType}
              uuid={uuid}
              onDownloadClick={onDownloadClick}
              fileName={fileName}
              isError={isError}
            />
          </ChatText>
        );
      default:
        return (
          <ChatText
            text={text}
            title={title}
            alignment={alignment}
            date={date}
          />
        );
    }
  };

  const chatBubble = () => {
    if (type === "notif") return <>{chatContent()}</>;

    switch (alignment) {
      case "in":
        return (
          <div className="bubble-start flex justify-start mb-2">
            <div className="avatar-container me-2">
              {showAvatar ? (
                <AvatarImage
                  avatar={avatar}
                  className="object-cover h-8 w-8 rounded-full"
                />
              ) : (
                <div className="w-8"></div>
              )}
            </div>
            {chatContent()}
          </div>
        );
      case "out":
        return (
          <div className="bubble-end flex justify-end mb-2">
            {chatContent()}
            {/* <div className="avatar-container ms-2">{showAvatar ? <ChatAvatar avatar={avatar} /> : <div className="w-8"></div>}</div> */}
          </div>
        );
      default:
        return <>{chatContent()}</>;
    }
  };

  return <div className="fade-in-up">{chatBubble()}</div>;
};

export default memo(ChatItem);
