import { deleteLocalStorage, setLocalStorage } from "../../../utils/localstorage";
import axiosInstance from "../../../utils/http-axios";
import { saveAs } from "file-saver";

// Define the event type
interface DownloadEvent {
  path: string;
  fileName: string;
}

// Function to convert ArrayBuffer to base64 string
function arrayBufferToBase64(buffer: ArrayBuffer): string {
  let binary = "";
  const bytes = new Uint8Array(buffer);
  const len = bytes.byteLength;
  for (let i = 0; i < len; i++) {
    binary += String.fromCharCode(bytes[i]);
  }
  return window.btoa(binary);
}

// Function to convert base64 to a File object
function base64ToFile(
  base64Data: string,
  contentType: string,
  filename: string
): File {
  const byteCharacters = atob(base64Data);
  const byteNumbers = new Array(byteCharacters.length);
  for (let i = 0; i < byteCharacters.length; i++) {
    byteNumbers[i] = byteCharacters.charCodeAt(i);
  }
  const byteArray = new Uint8Array(byteNumbers);
  return new File([byteArray], filename, { type: contentType });
}

const downloadHandler = async (event: DownloadEvent): Promise<void> => {
  try {
    // Fetch the file from the API
    const response = await axiosInstance.get(`/api/attachment/${event.path}`, {
      responseType: "arraybuffer", // Get response as ArrayBuffer
    });

    const filename = event.fileName || "downloadedFile";
    const contentType = response.headers["content-type"];

    // Convert ArrayBuffer to base64
    const base64Data = arrayBufferToBase64(response.data);

    // Create a file from the base64 data
    const file = base64ToFile(base64Data, contentType, filename);

    // Use saveAs to save the file
    saveAs(file);
  } catch (error) {
    console.error("Error downloading file:", error);
  }
};

const downloadMessages = async (code: string) => {
  const response = await axiosInstance.get(`/api/download-messages/${code}`, {
    responseType: "blob",
  });

  const filename = `Messages Ticket ${code}`;
  const blob = new Blob([response.data], {
    type: response.headers["content-type"],
  });
  const url = window.URL.createObjectURL(blob);
  const a = document.createElement("a");
  a.href = url;
  a.download = filename;
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
  window.URL.revokeObjectURL(url);
};

const setTicketInfoCookies = (code: string, tokenCode: string) => {
  setLocalStorage("_c", code);
  setLocalStorage("_tc", tokenCode);
};

const removeTicketInfoCookies = () => {
  deleteLocalStorage("_c");
  deleteLocalStorage("_tc");
};

export {
  downloadHandler,
  downloadMessages,
  setTicketInfoCookies,
  removeTicketInfoCookies,
};
