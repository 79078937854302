function findLastIndex(
  array: any[],
  predicate: (item: any) => boolean
): number {
  let lastIndex = -1;
  for (let i = 0; i < array.length; i++) {
    if (predicate(array[i])) {
      lastIndex = i;
    }
  }
  return lastIndex;
}

function moveFirstToLast(array: any[]) {
  if (array.length > 0) {
    let firstElement = array.shift(); // Remove the first element
    array.push(firstElement); // Add it to the end of the array
  }
  return array;
}

export { findLastIndex, moveFirstToLast };
