import ChatHeaderButton from "./ChatHeaderButton";
import { useAppContext, TicketState } from "../../../context/AppContext";
import AvatarImage from "./AvatarImage";

const ChatHeader = () => {
  const { ticket } = useAppContext();

  const isOnline = true;
  return (
    <div className="h-20 px-5 py-4 flex justify-between items-center bg-sky-700 text-white border-b-2 w-full z-2">
      <div className="flex justify-between w-full">
        <div className="flex">
          <div className="relative inline-block">
            <AvatarImage
              avatar={ticket?.agentAvatar}
              className="rounded-full w-10 h-10 bg-white"
            />
            {isOnline && (
              <div className="absolute bottom-0 right-0 h-3 w-3 bg-green-500 border border-white rounded-full"></div>
            )}
          </div>
          <div className="ml-3">
            <div className="font-semibold">
              {ticket?.currentAssignedAgentName ?? "HelpDesk Support"}
            </div>
            <div className="flex items-center text-xs">#{ticket?.code}</div>
          </div>
        </div>
        <div>
          <ChatHeaderButton />
        </div>
      </div>
    </div>
  );
};

export default ChatHeader;
