type Prop = {
  children?: any;
};

const BlockLoading = ({ children }: Prop) => {
  const styles = {
    borderRadius: "1rem",
    color: "#7e8299",
    fontWeight: "500",
    margin: "0",
    width: "100%",
    height: "100%",
    padding: "0.5rem 2rem",
    top: "0",
    left: "0",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    zIndex: 999
  };

  return (
    <div style={{ ...styles, position: "absolute", textAlign: "center" }}>
      <div
        className="bg-white flex px-4 py-3"
        style={{
          height: 40,
          borderRadius: "0.5rem",
          boxShadow: "0 0 50px 0 rgb(82 63 105 / 25%)",
        }}
      >
        {children ? children : <span className="text-xs">Memuat...</span>}
      </div>
    </div>
  );
};

export default BlockLoading;
