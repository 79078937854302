import ChatBox from "./components/ChatBox";
import ChatHeader from "./components/ChatHeader";
import { ChatBubbleOvalLeftEllipsisIcon } from "@heroicons/react/24/outline";
import ChatOperationalTime from "./components/ChatOperationalTime";
import axiosInstance from "../../utils/http-axios";
import { useEffect } from "react";
import { useAppContext } from "../../context/AppContext";
import { isWithinTimeRange } from "../../utils/date";

const ChatContainer = () => {
  const {
    setOperationalSetting,
    operationalSetting,
    setIsOperationalTime,
    isOperationalTime,
  } = useAppContext();

  const getSetting = async () => {
    const response = await axiosInstance.get(`/api/operational-time`);
    const { data, message, code } = response.data;

    if (code === "0000") {
      setOperationalSetting(data);
    }
  };

  useEffect(() => {
    setIsOperationalTime(
      !operationalSetting.isOffDay &&
        operationalSetting.enableOperationalTime == true &&
        isWithinTimeRange(
          operationalSetting.startOperationalTime ?? "",
          operationalSetting.endOperationalTime ?? ""
        )
    );
  }, [operationalSetting]);

  useEffect(() => {
    getSetting();
  }, []);

  return (
    <div
      className={`container flex flex-col mx-auto shadow-lg overflow-hidden relative full-height ${
        !isOperationalTime && "service-unavailable"
      }`}
    >
      <>
        <ChatHeader />
        <div className="grow relative">
          <ChatBox />
        </div>
      </>
      {/* <div className="absolute flex flex-col items-center justify-center mx-auto shadow-lg overflow-hidden full-height bg-black bg-opacity-40 w-full">
        <div className="animate-bounce text-white">
          <ChatBubbleOvalLeftEllipsisIcon className="h-10 w-10 text-WHITE" />
        </div>
      </div> */}
    </div>
  );
};

export default ChatContainer;
