// TypingIndicator.js
import React from "react";

const TypingIndicator = () => {
  const typingIndicatorStyles = {
    display: "flex",
    alignItems: "center",
    width: '40px',
    height: '20px'
  };

  return (
    <div style={typingIndicatorStyles}>
      <div className="typing">
        <span></span>
        <span></span>
        <span></span>
      </div>
      <style>
        {`
        .typing {
          position: relative;
          display: flex;
          align-items: center;
        }
        .typing span {
          content: "";
          -webkit-animation: blink 1.5s infinite;
                  animation: blink 1.5s infinite;
          -webkit-animation-fill-mode: both;
                  animation-fill-mode: both;
          height: 10px;
          width: 10px;
          background: #3b5998;
          border-radius: 50%;
          margin: 0 2px;
        }
        .typing span:nth-child(2) {
          -webkit-animation-delay: 0.2s;
                  animation-delay: 0.2s;
        }
        .typing span:nth-child(3) {
          -webkit-animation-delay: 0.4s;
                  animation-delay: 0.4s;
        }
        
        @-webkit-keyframes blink {
          0% {
            opacity: 0.1;
          }
          20% {
            opacity: 1;
          }
          100% {
            opacity: 0.1;
          }
        }
        
        @keyframes blink {
          0% {
            opacity: 0.1;
          }
          20% {
            opacity: 1;
          }
          100% {
            opacity: 0.1;
          }
        }
        `}
      </style>
    </div>
  );
};

export default TypingIndicator;
