import React, { useState } from "react";
import "./App.css";
import Routes from "./routes";
import { BrowserRouter as Router } from "react-router-dom";
import { AppProvider } from "./context/AppContext";
import { ToastContainer } from "react-toastify";
import { showError } from "./utils/toast";
import "react-toastify/dist/ReactToastify.css";
import {
  GoogleReCaptcha,
  GoogleReCaptchaProvider,
} from "react-google-recaptcha-v3";
import axiosInstance from "./utils/http-axios";
import {
  ArrowPathIcon,
  ExclamationTriangleIcon,
} from "@heroicons/react/24/solid";

function App() {
  let vh = window.innerHeight * 0.01;
  document.documentElement.style.setProperty("--vh", `${vh}px`);

  const [refreshReCaptcha, setRefreshReCaptcha] = useState(false);
  const [isValidCaptcha, setIsValidCaptcha] = useState<boolean | null>(null);
  const handleValidateCaptcha = async (token: string) => {
    try {
      const res = await axiosInstance.post(`/api/verify-captcha`, {
        token,
      });
      if (res.status === 200) {
        if (res.data.code === "0000") {
          setIsValidCaptcha(true);
          return;
        }
      }
      setIsValidCaptcha(false);
    } catch (err) {
      // setRefreshReCaptcha(!refreshReCaptcha);
      setIsValidCaptcha(false);
    }
  };
  const setTokenFunc = (token: string) => {
    handleValidateCaptcha(token);
  };

  return (
    <>
      <GoogleReCaptchaProvider
        reCaptchaKey={process.env.REACT_APP_CAPTCHA_SITE_KEY ?? ""}
      >
        <GoogleReCaptcha
          onVerify={setTokenFunc}
          refreshReCaptcha={refreshReCaptcha}
        />
      </GoogleReCaptchaProvider>
      <div className="App">
        {isValidCaptcha === null && (
          <div
            className="flex justify-center items-center absolute w-full bg-white/20 z-50"
            style={{ height: "100%" }}
          >
            <ArrowPathIcon className="h-4 w-4 me-1 inline-flex animate-spin" />
            <div className="text-sm">Validating ...</div>
          </div>
        )}

        {isValidCaptcha === false && (
          <div
            className="flex flex-row justify-center items-center absolute w-full bg-white/20 z-50"
            style={{ height: "100%" }}
          >
            <ExclamationTriangleIcon className="h-4 w-4 me-1 inline-flex" />
            <div className="text-sm">Invalid Access</div>
          </div>
        )}
        {isValidCaptcha === true && (
          <AppProvider>
            <Router>
              <Routes />
            </Router>
            <ToastContainer />
          </AppProvider>
        )}
      </div>
    </>
  );
}

export default App;
