import { useEffect, useState } from "react";

const API_URL = process.env.REACT_APP_API_URL;

const AvatarImage = ({ avatar, className }: any) => {
  const [imgSrc, setImgSrc] = useState("");
  const defaultImg = "/cs-avatar.png";

  useEffect(() => {
    if (avatar) {
      setImgSrc(`${API_URL}api/avatar/${avatar}`);
    } else {
      setImgSrc(defaultImg);
    }
  }, [avatar]);

  const handleError = () => {
    setImgSrc(defaultImg);
  };

  return <img src={imgSrc} className={` ${className}`} alt="avatar" onError={handleError} />;
};

export default AvatarImage;
