import ChatItem from "./ChatItem";
import ChatInput from "./ChatInput";
import ScrollableDiv from "../../layout/ScrollableDiv";
import { useState, useEffect, useCallback } from "react";
import axiosInstance from "../../../utils/http-axios";
import "./ChatBox.css";
import { dateNow } from "../../../utils/date";
import Connector from "../../../utils/signalr-connection";
import { v4 as uuidv4 } from "uuid";
import {
  downloadHandler,
  removeTicketInfoCookies,
  setTicketInfoCookies,
} from "../helpers/chat-helper";
import {
  getLocalStorage,
  setLocalStorage,
  deleteLocalStorage,
} from "../../../utils/localstorage";
import { useAppContext, TicketState } from "../../../context/AppContext";
import ChatRating from "./ChatRating";
import { showError } from "../../../utils/toast";
import { ArrowPathIcon } from "@heroicons/react/24/solid";
import ChatQueueInfo from "./ChatQueueInfo";
import ChatCanceled from "./ChatCanceled";
import { findLastIndex } from "../../../utils/array";
import MediaViewer from "./MediaViewer";
import ChatOperationalTime from "./ChatOperationalTime";
import {
  API_SERVICE_UNAVAILABLE_CODE,
  API_SUCCESS_CODE,
} from "../../../utils/constant";
import FileResizer from "react-image-file-resizer";
import BlockLoading from "./BlockLoading";
import TypingAnimation from "./TypingAnimation";
import TypingIndicator from "./TypingIndicator";

const ChatBox = () => {
  const [messages, setMessages] = useState<any[]>([]);
  const { ticket, setTicket, setOperationalSetting, setIsLoadingChat } =
    useAppContext();
  const { addEventHandler, unsubscribe, onConnected, isConnected } =
    Connector();
  const [signalrStatus, setSignalrStatus] = useState("off");
  const [isLoading, setIsLoading] = useState(false);

  const addNewMessage = useCallback((newMessage: any) => {
    setMessages((prev) => [...prev, newMessage]);
  }, []);

  useEffect(() => {
    console.log("updatesmessages", messages);
  }, [messages]);

  const updateMessagePath = useCallback((uuid: string, item: any) => {
    setMessages((states: any[]) => {
      let updatedStateIndex = states.findIndex((f) => f.uuid === uuid);
      const updatedStates = [...states];
      if (updatedStateIndex >= 0) {
        updatedStates[updatedStateIndex].filePath = item.filePath;
        updatedStates[updatedStateIndex].fileFullPath = item.fileFullPath;
        updatedStates[updatedStateIndex].fileName = item.fileName;
        updatedStates[updatedStateIndex].date = item.date;
      } else {
        console.log(uuid, "notfound");
        console.log(messages);
      }
      return updatedStates;
    });
  }, []);

  const setAttachmentError = useCallback((uuid: string) => {
    setMessages((states: any[]) => {
      let updatedStateIndex = states.findIndex((f) => f.uuid === uuid);
      const updatedStates = [...states];
      updatedStates[updatedStateIndex].isError = true;
      return updatedStates;
    });
  }, []);

  const removeList = useCallback(() => {
    setMessages((prev) => [...prev].filter((f) => f.type !== "list"));
  }, []);

  const removeLoading = useCallback(() => {
    setMessages((prev) => [...prev].filter((f) => f.type !== "loading"));
    setIsLoadingChat(false);
  }, []);

  const removeListAgentBusy = useCallback(() => {
    removeList();
    // console.log(messages);

    // const lastIndex = findLastIndex(
    //   messages,
    //   (item: any) => item.userType === "system" && item.isCategoryUser === true
    // );

    // if (lastIndex !== -1) {
    //   const newItems = [...messages];
    //   newItems.splice(lastIndex, 1);
    //   setMessages(newItems);
    // }
  }, []);

  const removeCategory = useCallback(() => {
    setMessages((prev) => [...prev].filter((f) => f.isCategoryUser !== true));
  }, []);

  const getCategoriesByCode = async (code: string) => {
    try {
      const response = await axiosInstance.get(
        `/api/category/${ticket?.tokenCode}/${code}`
      );

      if (response.data.code === API_SUCCESS_CODE) {
        return response.data.data;
      }

      if (response.data.code === API_SERVICE_UNAVAILABLE_CODE) {
        const response = await axiosInstance.get(`/api/operational-time`);
        const { data, message, code } = response.data;

        if (code === API_SUCCESS_CODE) {
          setOperationalSetting(data);
        }
      }
    } catch (error) {
      console.error("Error fetching Categories:", error);
    }
  };

  const sendMessage = async (message: string, isCategoryUser = false) => {
    try {
      const response = await axiosInstance.post(
        `/api/chat/${ticket?.tokenCode}`,
        {
          message,
          isCategoryUser,
        }
      );
      if (response.data.code === "0000") {
      }
    } catch (error) {
      console.error("Error fetching Chats:", error);
    }
  };

  const initTicket = async (
    ticketCode: string | null,
    token: string | null = null
  ) => {
    setIsLoading(true);
    try {
      const response = await axiosInstance.post(`/api/init`, {
        code: ticketCode,
        token: token,
      });
      const { data, message, code } = response.data;

      if (code === "0000") {
        console.log("before set");
        if (data.messages) setMessages(data.messages);
        console.log("after set");
        setTicketInfoCookies(data.code, data.tokenCode);
        setTicket({
          code: data.code,
          tokenCode: data.tokenCode,
          allowChat: data.allowChat,
          isReset: false,
          currentAssignedAgentName: data.currentAssignedAgentName,
          agentAvatar: data.agentAvatar,
          status: data.status,
          queueNumber: data.queueNumber,
          isNeedUserResponse: data.isNeedUserResponse,
          showContactAgent: data.showContactAgent,
          isAllowRating: data.isAllowRating,
        });
      }
    } catch (error: any) {
      console.error("Error fetching Categories:", error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    const ticketCode = getLocalStorage("_tc");
    const token = getLocalStorage("_t");
    initTicket(ticketCode || null, token);
  }, []);

  const openNewTicketHandler = () => {
    removeTicketInfoCookies();
    const token = getLocalStorage("_t");
    initTicket(null, token);
  };

  const onListClickHandler = async ({ name, id, code }: any) => {
    if (code === "CLOSE") {
      unsubscribe(ticket?.code ?? "");
      const token = getLocalStorage("_t");
      initTicket(null, token);

      return;
    }
    removeCategory();

    addNewMessage({
      type: "text",
      date: dateNow(),
      alignment: "in",
      text: name,
      items: [],
      userType: "user",
      userId: null,
      avatar: "",
      isCategoryUser: true,
    });

    const resp = await getCategoriesByCode(code);
    if (!resp) return;

    if (resp.status === 23) {
      removeListAgentBusy();
      unsubscribe(ticket?.code ?? "");
      const token = getLocalStorage("_t");
      initTicket(null, token);
    }
    if (resp.status === 20) {
      let newState = { ...ticket };
      newState.status = 20;

      setTicket(newState);
    }
  };

  const onNewMessage = (message: string) => {
    if (!message) return;

    addNewMessage({
      type: "text",
      date: dateNow(),
      alignment: "out",
      text: message,
      items: [],
      userType: "user",
      userId: null,
      avatar: "",
    });

    sendMessage(message);
  };

  useEffect(() => {
    const handleSignalRConnected = () => {
      setSignalrStatus("on");
    };

    if (isConnected()) {
      setSignalrStatus("on");
    }

    onConnected(handleSignalRConnected);
  }, []);

  useEffect(() => {
    if (ticket?.isReset) {
      initTicket(ticket?.code || null);
    } else {
      unsubscribe(ticket?.code ?? "");
      addEventHandler(ticket?.code ?? "", (_, message) => {
        const messageObj = JSON.parse(message);
        let newTicket = { ...ticket };

        console.log(messageObj);

        if (messageObj.userType === "user") return;

        if (messageObj.type === "list") removeList();
        if (messageObj.type === "loading") {
          removeLoading();
          setIsLoadingChat(true);
        } else if (messageObj.type === "showagent") {
          newTicket = {
            ...newTicket,
            showContactAgent: messageObj.showContactAgent,
          };

          setTicket(newTicket);
          return;
        } else {
          removeLoading();
        }
        addNewMessage(messageObj);

        const allowChat = messageObj?.allowChat ?? false;
        let agentName = ticket?.currentAssignedAgentName;

        if (
          (messageObj?.userType === "agent" ||
            messageObj?.userType === "system") &&
          messageObj?.userName
        ) {
          agentName = messageObj?.userName;
        }
        newTicket = {
          ...newTicket,
          allowChat: allowChat,
          isNeedUserResponse: messageObj?.isNeedUserResponse ?? false,
          showContactAgent: messageObj?.showContactAgent ?? false,
          isAllowRating: messageObj?.isAllowRating ?? false,
          currentAssignedAgentName: agentName,
        };

        if (messageObj.status) {
          newTicket = { ...newTicket, status: messageObj.status };
        }
        if (messageObj.avatar) {
          newTicket = { ...newTicket, agentAvatar: messageObj.avatar };
        }
        setTicket(newTicket);

        if (messageObj.status === 40 || messageObj.status === 23) {
          removeListAgentBusy();
          unsubscribe(ticket?.code ?? "");
        }
      });
    }
  }, [ticket, setTicket]);

  const resizeFile = (file: File): Promise<File> =>
    new Promise((resolve) => {
      FileResizer.imageFileResizer(
        file,
        720,
        720,
        "JPEG",
        100,
        0,
        (uri) => {
          resolve(uri as File);
        },
        "file"
      );
    });

  const attachmentChangeHandler = async (file: File) => {
    let reqFile = file;

    if (file && file.type.startsWith("image/")) {
      reqFile = await resizeFile(file);
    }

    const formData = new FormData();
    formData.append("file", reqFile);
    const reader = new FileReader();
    const uuid = uuidv4();

    reader.onloadend = async () => {
      addNewMessage({
        type: "attachment",
        alignment: "out",
        userType: "user",
        userId: null,
        avatar: "",
        fileFullPath: reader.result,
        filePath: reader.result,
        fileMimeType: file.type,
        uuid,
      });
      try {
        const response = await axiosInstance.post(
          `/api/attachment/${ticket?.tokenCode}`,
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );
        if (response.data.code === "0000") {
          updateMessagePath(uuid, response.data.data);
        } else {
          setAttachmentError(uuid);
          showError(response.data.message);
        }
      } catch (e: any) {
        setAttachmentError(uuid);
        showError(e.message);
      }
    };
    reader.readAsDataURL(file);
  };

  const footerComponent = () => {
    switch (ticket.status) {
      case 23:
        return (
          <div
            className="bg-white"
            style={{
              height: 160,
              position: "sticky",
              bottom: 0,
              width: "100%",
            }}
          >
            <ChatCanceled onOpenNewTicket={openNewTicketHandler} />
          </div>
        );
      case 40:
        return (
          <div
            className="bg-white"
            style={{
              position: "sticky",
              bottom: 0,
              width: "100%",
            }}
          >
            <ChatRating
              ticket={ticket}
              onOpenNewTicket={openNewTicketHandler}
            />
          </div>
        );
      default:
        return (
          <div
            style={{
              minHeight: 90,
              position: "sticky",
              bottom: 0,
              width: "100%",
              backgroundColor: ticket?.allowChat ? "#fff" : "transparent",
            }}
          >
            {ticket?.allowChat && (
              <div className="p-5 border-t border-gray-200">
                <ChatInput
                  onEnter={onNewMessage}
                  onAttachmentChange={attachmentChangeHandler}
                  openNewTicketHandler={openNewTicketHandler}
                />
              </div>
            )}
          </div>
        );
    }
  };

  const [openMedia, setOpenMedia] = useState(false);
  const [selectedMedia, setSelectedMedia] = useState<any | null>(null);

  const onOpenMedia = ({ fullpath, fileName }: any) => {
    setSelectedMedia({ fullpath, fileName });
    setOpenMedia(true);
  };

  const onCloseMedia = () => {
    setOpenMedia(false);
    setSelectedMedia(null);
  };

  // const [hasBlurred, setHasBlurred] = useState(false);

  // useEffect(() => {
  //   const handleFocus = () => {
  //     if (hasBlurred) {
  //       const ticketCode = getLocalStorage("_tc");
  //       const token = getLocalStorage("_t");
  //       initTicket(ticketCode || null, token);
  //       setHasBlurred(false); // Reset the blurred state
  //     }
  //   };

  //   const handleBlur = () => {
  //     setHasBlurred(true);
  //   };

  //   window.addEventListener("focus", handleFocus);
  //   window.addEventListener("blur", handleBlur);

  //   return () => {
  //     window.removeEventListener("focus", handleFocus);
  //     window.removeEventListener("blur", handleBlur);
  //   };
  // }, [hasBlurred]);

  return (
    <>
      {isLoading && <BlockLoading />}
      <div className="flex flex-row justify-between bg-white h-full">
        {selectedMedia && (
          <MediaViewer
            isOpen={openMedia}
            onClose={onCloseMedia}
            title=""
            mediaUrl={selectedMedia?.fullpath}
            fileName={selectedMedia?.fileName}
          />
        )}
        <div className="w-full flex flex-col grow justify-between">
          <>
            <ScrollableDiv height={"100%"}>
              <div className="flex flex-col mt-5 px-5">
                {messages.map((m, index) => {
                  let prev = null;
                  if (index > 0) prev = messages[index - 1];
                  const showAvatar =
                    prev === null
                      ? true
                      : !(
                          prev.userType === m.userType &&
                          prev.userId === m.userId
                        );
                  const alignment = m.userType === "user" ? "out" : "in";

                  return (
                    <ChatItem
                      key={index}
                      avatar={m.avatar}
                      showAvatar={showAvatar}
                      alignment={alignment}
                      type={m.type}
                      text={m.text}
                      title={m.title}
                      date={m.date}
                      onListClick={onListClickHandler}
                      items={m.items}
                      path={m.filePath}
                      fullpath={m.fileFullPath}
                      mimeType={m.fileMimeType}
                      uuid={m.uuid}
                      fileName={m.fileName}
                      isError={m.isError}
                      onDownloadClick={onOpenMedia}
                    />
                  );
                })}
                {ticket?.status === 20 ? (
                  <ChatQueueInfo
                    ticketCode={ticket.tokenCode ?? ""}
                    initNumber={ticket.queueNumber ?? 0}
                  />
                ) : (
                  ""
                )}
              </div>
            </ScrollableDiv>
            <ChatOperationalTime />
            {footerComponent()}
          </>
          {signalrStatus === "off" && (
            <div
              className="flex justify-center items-center absolute w-full bg-white/20 z-50"
              style={{ height: "100%" }}
            >
              <ArrowPathIcon className="h-4 w-4 me-1 inline-flex animate-spin" />
              <div className="text-xs">Connecting ...</div>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default ChatBox;
