import React, { useState, useEffect, useRef } from "react";
import { EllipsisVerticalIcon } from "@heroicons/react/24/solid";
import axiosInstance from "../../../utils/http-axios";
import { useAppContext } from "../../../context/AppContext";
import Connector from "../../../utils/signalr-connection";
import { downloadMessages } from "../helpers/chat-helper";
const { unsubscribe } = Connector();

const Dropdown: React.FC = () => {
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef<HTMLDivElement>(null);
  const { ticket, setTicket } = useAppContext();

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const closeDropdown = () => {
    setIsOpen(false);
  };

  //TODO tokenCode
  const closeAndNewHandler = async () => {
    try {
      const response = await axiosInstance.post(`/api/close-and-new/${ticket?.code}`);
      const { data, message, code } = response.data;
      if (code === "0000") {
        unsubscribe(ticket?.code ?? "");
        setTicket({ code: data.code, allowChat: data.allowChat, isReset: true, status: data.status });
      }
    } catch (error) {
      console.error("Error fetching Categories:", error);
    }
  };

  const downloadTextHandler = async () => {
    try {
      downloadMessages(ticket?.tokenCode ?? '');
    } catch (error) {
      console.error("Error fetching Categories:", error);
    }
  }

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
        closeDropdown();
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div ref={dropdownRef} className="relative inline-block text-left">
      {/* <button type="button" className="inline-flex items-center px-4 py-2 rounded-md bg-sky-700 text-white text-sm font-medium" id="options-menu" onClick={toggleDropdown}>
        <EllipsisVerticalIcon className="h-6 w-6 text-white" />
      </button> */}

      {isOpen && (
        <div className="origin-top-right absolute right-0 mt-2 w-32 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 z-10">
          <div className="py-1" role="menu" aria-orientation="vertical" aria-labelledby="options-menu">
            <button type="button" className="block w-full px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900" role="menuitem" onClick={closeAndNewHandler}>
              Close And New
            </button>
          </div>
          {/* <div className="py-1" role="menu" aria-orientation="vertical" aria-labelledby="options-menu">
            <button type="button" className="block w-full px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900" role="menuitem" onClick={downloadTextHandler}>
              Download Messages
            </button>
          </div> */}
        </div>
      )}
    </div>
  );
};

export default Dropdown;
