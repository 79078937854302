import { useState, useRef, useEffect } from "react";
import {
  PaperClipIcon,
  PaperAirplaneIcon,
  HandThumbUpIcon,
  HandThumbDownIcon,
  ChatBubbleLeftEllipsisIcon,
  ArrowUturnLeftIcon,
  CheckBadgeIcon,
} from "@heroicons/react/24/solid";
import { showError } from "../../../utils/toast";
import { useAppContext } from "../../../context/AppContext";
import axiosInstance from "../../../utils/http-axios";

const MAX_FILE_SIZE = 5;

const ChatInput = ({
  alignment,
  onEnter,
  disabled = true,
  onAttachmentChange,
  openNewTicketHandler,
}: any) => {
  const { ticket, setTicket, isLoadingChat } = useAppContext();
  const [keyword, setKeyword] = useState("");
  const fileInputRef = useRef<HTMLInputElement>(null);
  const handleKeyPress = (event: any) => {
    if (event.key === "Enter") {
      event.preventDefault();
      submitHandler();
    }
  };
  const valueChangedHandler = (event: any) => {
    setKeyword(event.target.value);
  };

  const attachmentClickHandler = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const handleFileChange = (event: any) => {
    const selectedFile = event.target.files[0];
    if (!selectedFile) {
      return;
    }
    const allowedTypes = [
      "image/jpeg",
      "image/png",
      "application/pdf",
      "video/mp4",
      "video/avi",
      "video/mpeg",
      "video/quicktime",
    ];
    const maxSizeInBytes = MAX_FILE_SIZE * 1024 * 1024;

    if (!allowedTypes.includes(selectedFile.type)) {
      showError(
        "File type not allowed. Please select a JPEG, PNG, or PDF file."
      );
      return;
    }
    if (selectedFile.size > maxSizeInBytes) {
      showError(
        `File size exceeds the ${MAX_FILE_SIZE} MB limit. Please select a smaller file.`
      );
      return;
    }

    onAttachmentChange(selectedFile);
    event.target.value = null;
  };

  const submitHandler = () => {
    if (keyword.length < 2) {
      showError("Harap input kata minimal 2 karakter");

      return;
    }
    onEnter(keyword);
    setKeyword("");
  };

  const updateIsNeedResponseLocal = () => {
    let _ticket = { ...ticket };
    _ticket.isNeedUserResponse = false;
    _ticket.showContactAgent = false;
    setTicket(_ticket);
  };

  const continueChatHandler = async () => {
    await updateIncrementUnHelpfulResponse();
  };

  const helpfulResponseHandler = async () => {
    updateIsNeedResponseLocal();
    await resetUnHelpfulResponse();
  };

  const updateIncrementUnHelpfulResponse = async () => {
    try {
      await axiosInstance.put(`/api/chat/unhelpful/${ticket?.tokenCode}`);
    } catch (e: any) {}
  };

  const resetUnHelpfulResponse = async () => {
    try {
      await axiosInstance.put(`/api/chat/helpful/${ticket?.tokenCode}`);
    } catch (e: any) {}
  };

  const contactAgent = async () => {
    try {
      await axiosInstance.get(`/api/category/${ticket?.tokenCode}/CONTACT`);
    } catch (e: any) {}
  };

  const KeepAskingItem = () => {
    return (
      <div
        className="bg-gray-100 rounded-3xl text-black px-5 py-3 flex hover:bg-gray-200 cursor-pointer items-center"
        onClick={helpfulResponseHandler}
      >
        <ArrowUturnLeftIcon className="h-4 w-4 text-gray-500" />
        <span className="ps-2 text-center">Lanjut bertanya</span>
      </div>
    );
  };

  const ContactAgentItem = () => {
    return (
      <div
        className="bg-gray-100 rounded-3xl text-black px-5 py-3 flex hover:bg-gray-200 cursor-pointer items-center"
        onClick={contactAgent}
      >
        <ChatBubbleLeftEllipsisIcon className="h-4 w-4 text-gray-500" />
        <span className="ps-2 text-center">Hubungi Agent</span>
      </div>
    );
  };

  const CloseTicketItem = () => {
    return (
      <div
        className="bg-gray-100 rounded-3xl text-black px-5 py-3 flex hover:bg-gray-200 cursor-pointer items-center"
        onClick={openNewTicketHandler}
      >
        <CheckBadgeIcon className="h-4 w-4 text-gray-500" />
        <span className="ps-2 text-center">Tutup Tiket</span>
      </div>
    );
  };

  const UnsatisfiedAnswerItem = () => {
    return (
      <div
        className="bg-gray-100 rounded-3xl text-black px-5 py-3 flex hover:bg-gray-200 cursor-pointer items-center"
        onClick={continueChatHandler}
      >
        <HandThumbDownIcon className="h-4 w-4 text-gray-500" />
        <span className="ps-2 text-center">Jawaban tidak sesuai</span>
      </div>
    );
  };

  return ticket.isNeedUserResponse || ticket?.showContactAgent ? (
    <>
      <div>
        <div className="text-xs text-center mb-3">
          <span>
            {ticket?.showContactAgent
              ? "Apakah anda ingin menghubungi agent kami?"
              : "Apakah jawaban yang diberikan sudah membantu?"}
          </span>
        </div>
        <div className="flex justify-center flex-wrap text-xs gap-2 mb-6">
          <KeepAskingItem />
          {ticket?.showContactAgent ? (
            <>
              <ContactAgentItem />
              <CloseTicketItem />
            </>
          ) : (
            <UnsatisfiedAnswerItem />
          )}
        </div>
      </div>
    </>
  ) : (
    <>
      <div className="flex items-center">
        <textarea
          rows={1}
          value={keyword}
          maxLength={150}
          onChange={valueChangedHandler}
          className={
            "w-full px-5 py-4 rounded-full text-xs " +
            (isLoadingChat ? "bg-white text-center" : "bg-gray-100 ")
          }
          style={{
            resize: "none",
          }}
          placeholder={
            isLoadingChat ? "Harap menunggu jawaban ..." : "Masukkan pesan ..."
          }
          onKeyDown={handleKeyPress}
          disabled={isLoadingChat ?? false}
        ></textarea>
        <div className="ps-4 flex align-center">
          {keyword ? (
            <button type="button" onClick={submitHandler}>
              <PaperAirplaneIcon className="h-6 w-6 text-gray-500" />
            </button>
          ) : (
            (ticket.status ?? 0) > 25 && (
              <button type="button" onClick={attachmentClickHandler}>
                <PaperClipIcon className="h-6 w-6 text-gray-500" />
              </button>
            )
          )}

          <input
            type="file"
            ref={fileInputRef}
            onChange={handleFileChange}
            style={{ display: "none" }}
            accept="image/jpeg, image/png, application/pdf, video/mp4, video/avi, video/mpeg, video/quicktime"
          />
        </div>
      </div>
    </>
  );
};

export default ChatInput;
