import React, { useState, useEffect } from "react";
import {
  ArrowDownTrayIcon,
  ArrowPathIcon,
  ExclamationTriangleIcon,
} from "@heroicons/react/24/solid";
import { showError, showInfo } from "../../../utils/toast";
import MediaViewer from "./MediaViewer";

const ChatAttachment = ({
  fileName,
  path,
  fullpath,
  alignment,
  mimeType,
  uuid,
  onDownloadClick,
  isError,
}: any) => {
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    setLoading(path.includes("data:"));
  }, [path]);

  const downloadHandler = () => {
    onDownloadClick({
      path,
      fileName,
      fullpath,
    });
  };

  const getThumb = (mimeType: string, path: string) => {
    if (mimeType === "application/pdf") {
      return "/pdf.svg";
    }
    if (mimeType.includes("video")) {
      return "/mov.svg";
    }

    return path;
  };

  return (
    <>
      <div
        className={`group relative w-40 overflow-hidden rounded-lg cursor-pointer ${
          alignment === "in" ? "justify-start" : "justify-end"
        }`}
      >
        <img
          src={getThumb(mimeType, fullpath)}
          alt={fileName}
          className="w-full rounded-lg transition-opacity"
        />
        {loading ? (
          <div className="absolute inset-0 bg-black bg-opacity-50 flex items-center justify-center">
            <div className="absolute inset-0 flex items-center justify-center bg-gray-800 bg-opacity-50">
              {isError ? (
                <ExclamationTriangleIcon className="h-6 w-6 text-white" />
              ) : (
                <ArrowPathIcon className="h-6 w-6 text-white animate-spin" />
              )}
            </div>
          </div>
        ) : (
          <div className="absolute inset-0 bg-black bg-opacity-50 flex items-center justify-center opacity-0 group-hover:opacity-100 transition-opacity duration-500 ease-in-out">
            <a
              type="button"
              className="w-full h-full flex items-center justify-center"
              onClick={downloadHandler}
            >
              <ArrowDownTrayIcon className="h-6 w-6 text-white" />
            </a>
          </div>
        )}
      </div>
    </>
  );
};

export default ChatAttachment;
