import { toast, Bounce } from "react-toastify";

const showError = (message: string) => {
  toast(message, {
    position: "bottom-center",
    autoClose: 2000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "light",
    type: "error",
    transition: Bounce,
    style: {
      fontSize: 13,
      marginBottom: 76
    },
  });
};

const showInfo = (message: string) => {
  toast(message, {
    position: "bottom-center",
    autoClose: 1000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "dark",
    transition: Bounce,
  });
};

export { showError, showInfo };
